import React from 'react';
import { createRoot } from 'react-dom/client';
import PaymentPopup from './PaymentPopup';
import ChargePopup from './ChargePopup';
import './styles.css';

const getWalletAddressFromURL = () => {
    const params = new URLSearchParams(window.location.search);
    return params.get('address') || '';
};

const ProductCard = ({ onPay }) => (
    <div className="product-card">
        <div className="logo-container">
            <img src="/images/BitgreenHeader.png" alt="Bitgreen" className="header-logo" />
        </div>
        <h1>OneMonth Deposit Gateway</h1>
        <button onClick={() => onPay(700)} className="pay-button">
            Buy BBB Tokens
        </button>
        <div className="privacy-notice">
            <div className="privacy-content">
                <p className="privacy-title">Data Privacy Notice</p>
                <p>Your security is our priority. All transactions are encrypted and processed through secure payment channels.</p>
                <p>By proceeding with the purchase, you agree to our <a href="/terms.html">Terms of Service</a> and acknowledge our <a href="https://bitgreen.org/privacy">Privacy Policy</a>.</p>
                <p className="compliance">Compliant with GDPR and international data protection regulations.</p>
            </div>
            <div className="security-badges">
                <span className="badge">🔒 256-bit SSL</span>
                <span className="badge">✓ Secure Payments</span>
            </div>
        </div>
    </div>
);

const App = () => {
    const [showPayment, setShowPayment] = React.useState(false);
    const [showCharge, setShowCharge] = React.useState(false);
    const [selectedAmount, setSelectedAmount] = React.useState(0);
    const [prefillAddress] = React.useState(getWalletAddressFromURL());

    const handlePaymentSuccess = (paymentDetails) => {
        console.log('Payment successful:', paymentDetails);
        setShowPayment(false);
        alert('Payment successful! Your BBB tokens will be delivered to your wallet shortly.');
    };

    const handleChargeSuccess = (chargeDetails) => {
        console.log('Charge successful:', chargeDetails);
        setShowCharge(false);
        alert('Payment successful! Your BBB tokens will be delivered to your wallet shortly.');
    };

    const handlePay = (amount) => {
        setSelectedAmount(amount);
        setShowPayment(true);
    };

    return (
        <div className="app-container">
            <ProductCard 
                onPay={handlePay}
            />
            
            {showPayment && (
                <PaymentPopup
                    onClose={() => setShowPayment(false)}
                    onSuccess={handlePaymentSuccess}
                    amount={selectedAmount}
                    prefillAddress={prefillAddress}
                />
            )}
            
            {showCharge && (
                <ChargePopup
                    onClose={() => setShowCharge(false)}
                    onSuccess={handleChargeSuccess}
                />
            )}
            <div className="app-footer">
                &copy; 2024 Bitgreen Switzerland Association. All rights reserved.
            </div>
        </div>
    );
};

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<App />);
